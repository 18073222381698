<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import ConditionTable from './components/condition_table.vue';
import ConditionDetailTableDiscount from './components/condition_detail_table.vue';

formCreate.component('ConditionTableDiscount', ConditionTable);
formCreate.component('ConditionDetailTableDiscount', ConditionDetailTableDiscount);

export default {
  extends: Form,
  props: {
    functionCode: String,
    modalFormData: Object,
  },
  data() {
    return {
      rule: [],
      manualOptions: [],
      detailOptions: [],
      buttons: {
        submit: false,
        close: false,
        loading: false,
        submitAudit: false,
      },
    };
  },
  watch: {
    functionCode(n) {
      if (n === 'fee_discount_condition') {
        this.hiddenFields(false, 'onAccountDetail');
        this.hiddenFields(true, 'discountDetail');
        this.getDetailOptions('6');
      } else {
        this.hiddenFields(true, 'onAccountDetail');
        this.hiddenFields(false, 'discountDetail');
        this.getDetailOptions('8');
      }
      this.setValue({
        operationType: '',
      });
    },
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    this.rule = await this.getBeforFormRule('fee_discount_goods_condition_form');
    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'onAccountDetail' || rowData.field === 'discountDetail') {
        rowData.props = {
          ...rowData.props,
          poolCode: this.modalFormData.poolCode,
          operationType: '',
        };
        rowData.on = {
          ...rowData.on,
          adjusTable: () => {
            this.$emit('adjusTable');
            this.getData();
          },
        };
      }
      rowData.props = {
        ...rowData.props,
        disabled: true,
      };
      if (rowData.field === 'operationType') {
        rowData.props = {
          ...rowData.props,
          disabled: false,
        };
        rowData.on = {
          change: (val) => {
            this.getRule('onAccountDetail').props.operationType = val;
            this.getRule('discountDetail').props.operationType = val;
          },
        };
      }
      return rowData;
    });
    this.reload(this.rule);
    this.getDetailOptions('6');
    if (this.modalFormData.id) {
      this.getData();
    }
    this.hiddenFields(true, 'discountDetail');
  },
  methods: {
    getData() {
      const path = '/fee/feePoolController/queryByPoolCode';
      request
        .get(path, { poolCode: this.modalFormData.poolCode })
        .then((res) => {
          if (res.success) {
            this.setValue({
              ...res.result,
              operationType: '',
            });
          }
        });
    },
    // 获取明细调整类型
    getDetailOptions(queryType) {
      const path = '/fee/feePoolController/getOperationTypeSelectList';
      const params = {
        queryType,
      };
      request
        .get(path, params)
        .then((res) => {
          if (res.success) {
            const operationType = this.getRule('operationType');
            operationType.options = res.result.map((v) => ({
              value: v.value,
              label: v.text,
            }));
          }
        });
    },
  },
};
</script>
